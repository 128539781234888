import { render, staticRenderFns } from "./DummyDashboard.vue?vue&type=template&id=1fc65caf&scoped=true"
import script from "./DummyDashboard.vue?vue&type=script&lang=js"
export * from "./DummyDashboard.vue?vue&type=script&lang=js"
import style0 from "./DummyDashboard.vue?vue&type=style&index=0&id=1fc65caf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc65caf",
  null
  
)

export default component.exports