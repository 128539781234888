<template>
  <div class="col-12" v-cloak @click="menu = false">
    <!-- overlay div -->
    <div class="team-overlay"></div>

    <!-- Alert to show user why his dashboard is disable -->
    <div>
      <v-alert
        v-if="active_team_members_list.length <= 1"
        class="ma-0"
        border="left"
        colored-border
        color="#304FFE"
      >
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Please invite your Team Members, you don't have any members yet.
</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToTeamMembers"
        >
          <div class="mt">Invite new members</div>
        </div>
      </v-alert>
      <v-alert v-else class="ma-0" border="left" colored-border color="#304FFE">
        <v-icon color="#304FFE" size="40" class="mx-3"
          >mdi-account-question-outline</v-icon
        >
        <span class="mt-8"
          >Sorry you don't have any stats yet, Please ask your team members to
          download the Desktop App.</span
        >
        <div
          class="float-right white--text promt-btn align-center pointer"
          @click="routeToDownloadApp"
        >
          <div class="mt">Download App</div>
        </div>
      </v-alert>
    </div>

    <div class="nill-opacity mt-3" id="charts-wrapper">
      <!-- Dashboard header -->
      <div class="d-flex">
        <div>
          <h5 class="mb-0 mt-4">Today's Progress</h5>
        </div>
        <v-spacer></v-spacer>
        <div class="mr-3">
          <label class="date-range-label mb-2 mt-3">Date Range</label>
          <div class="date-range-input py-1">
            <span class="today-date">Today</span>
            <v-icon class="float-right mr-2">mdi-calendar-range</v-icon>
          </div>
        </div>
      </div>

      <!-- Dashboard widgets new -->
      <div class="d-flex flex-wrap align-items-center">
        <div
          elevation="0"
          width="330"
          class="mr-4 mt-3 d-flex flex-column justify-content-center align-items-center cards"
        >
          <h6 class="mt-3">Employees</h6>
          <div class="my-3">
            <img
              src="../../assets/images/dashboard_icons/Employee Status SVG.svg"
            />
          </div>
          <div class="row status">
        <div class="col-md-6 text-center active-status">
          <div class="number">20</div>
          <div style="margin-left: -14px" class="mt-5">
            <v-icon class="mr-2" x-small color="#4acf8b"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Worked</span>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="number" >10</div>
          <div style="margin-left: -11px" class="mt-5">
            <v-icon class="mr-2" x-small color="#ff0000"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Absent</span>
          </div>
        </div>
      </div>
        </div>
        <div
          width="330"
          elevation="0"
          class="mr-4 mt-3 d-flex flex-column justify-content-center align-items-center cards"
        >
          <h6 class="mt-3">Projectsaaa</h6>
          <div class="my-3">
            <img src="../../assets/images/dashboard_icons/project_stats.svg" />
          </div>
          <div class="row status">
        <div class="col-md-12 text-center">
         
          <div class="number">
            5
          </div>
          <div style="margin-left: -14px" class="mt-5">
            <v-icon class="mr-2" x-small color="#780ae5"
              >mdi-checkbox-blank-circle</v-icon
            >
            <span class="time-text">Active</span>
          </div>
        </div>
      </div>
        </div>
        <div
          width="330"
          elevation="0"
          class="mr-4 mt-3 d-flex flex-column justify-content-center align-items-center cards"
        >
          <h6 class="mt-3">Time Worked</h6>
          <div class="my-3">
            <img
              src="../../assets/images/dashboard_icons/dashboard_clock.svg"
            />
          </div>
          <div class="col-sm-12 m-0 p-0 text-center">
        <div class="counts mt-1 time-value" title="Hours:Minutes">
          <span class="mr-1" >
            04
          </span>
          <span class="font-weight-normal mr-1">:</span>
          <span >
            30
          </span>
        </div>
        <div class="counts mt-0 time-text" data-v-a8321274="" title="">
          <span style="margin-left: 8px">Hrs : Mins</span>
        </div>
      </div>
        </div>
      </div>

      <!-- Project Tiles -->
      <div class="mt-7"><h5>Projects</h5></div>
      <div class="mt-3 d-flex flex-wrap align-items-center">
        <div class="my-3 mr-3" v-for="project in Projects" :key="project.id">
          <div class="d-flex flex-column tiles align-items-center">
            <img
              class="mt-5"
              src="https://bt.stafftimerapp.com/img/no_image.24b57e28.svg"
              alt=""
            />
            <h6 class="mt-5">{{ project.name }}</h6>
            <div class="w-100 mt-3">
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                Logged Hours
                <span class="float-right"> {{ project.logged_hours }} </span>
              </div>
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                No of Employees
                <span class="float-right"> {{ project.no_of_employees }} </span>
              </div>
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                No of Tasks
                <span class="float-right"> {{ project.no_of_tasks }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Team members tiles -->
      <div class="mt-4"><h5>Team Members</h5></div>
      <div class="mt-3 d-flex flex-wrap align-items-center">
        <div
          class="my-3 mr-3"
          v-for="team_member in TeamMembers"
          :key="team_member.id"
        >
          <div class="d-flex tiles flex-column align-items-center">
            <img class="mt-5" src="../../assets/images/no-member.svg" alt="" />
            <h6 class="mt-5">{{ team_member.name }}</h6>
            <div class="w-100 mt-3">
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                Logged Hours
                <span class="float-right">
                  {{ team_member.logged_hours }}
                </span>
              </div>
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                No of Projects
                <span class="float-right">
                  {{ team_member.no_of_projects }}
                </span>
              </div>
              <v-divider class="zero-margin-padding"></v-divider>
              <div class="mx-2 my-1">
                No of Tasks
                <span class="float-right"> {{ team_member.no_of_tasks }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "firstdashboard",
  data() {
    return {
      Projects: [
        {
          name: "Game App",
          logged_hours: "3:03",
          no_of_employees: "3",
          no_of_tasks: "4",
        },
        {
          name: "Social App",
          logged_hours: "2:09",
          no_of_employees: "6",
          no_of_tasks: "9",
        },
        {
          name: "Security Framework",
          logged_hours: "4:43",
          no_of_employees: "10",
          no_of_tasks: "14",
        },
        {
          name: "CrossWorld",
          logged_hours: "5:10",
          no_of_employees: "12",
          no_of_tasks: "7",
        },
        {
          name: "Legal Isuses",
          logged_hours: "3:46",
          no_of_employees: "8",
          no_of_tasks: "2",
        },
      ],
      TeamMembers: [
        {
          name: "Jessica Sweth",
          logged_hours: "3:03",
          no_of_projects: "3",
          no_of_tasks: "4",
        },
        {
          name: "John Hopkins",
          logged_hours: "2:09",
          no_of_projects: "6",
          no_of_tasks: "9",
        },
        {
          name: "Ariana Breth",
          logged_hours: "4:43",
          no_of_projects: "10",
          no_of_tasks: "14",
        },
        {
          name: "Rachel Green",
          logged_hours: "5:10",
          no_of_projects: "12",
          no_of_tasks: "7",
        },
        {
          name: "Monica Bing",
          logged_hours: "3:46",
          no_of_projects: "8",
          no_of_tasks: "2",
        },
      ],
    };
  },
  computed: {
    ...mapState("custom", ["selected_company", "active_team_members_list"]),
  },
  methods: {
    routeToTeamMembers() {
      this.$router.push({
        name: "TeamMembers",
        query: {
          new: "yes",
        },
      });
    },
    routeToDownloadApp() {
      return this.$router.push({
        name: "DownloadApp",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style scoped>
.date-range-input {
  border: 1px solid grey;
  width: 250px;
}
.today-date{
  margin-right: 160px;
}
.date-range-label {
  font-weight: 500;
  margin-right: 150px;
}
.pointer {
  cursor: pointer;
}
.cards {
  z-index: 0.1 !important;
  width: 330px;
}
.tiles {
  width: 230px;
}

.mt {
  margin-top: 5px !important;
}
.promt-btn {
  width: 175px !important;
  border-radius: 5px;
  height: 35px;
  text-align: center;
  vertical-align: center;
  background-color: #304ffe;
  z-index: 1 !important;
  animation-name: bounce-2 !important;
  animation-timing-function: ease !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.w-100 {
  width: 100% !important;
}
.zero-margin-padding {
  margin: 0px !important;
  padding: 0px !important;
}
.team-overlay {
  position: fixed;
  z-index: 0.9;
  background: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
}
.selected-date {
  border: 1px solid #e9f1fe !important;
  font-size: 12px !important;
  position: absolute !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: auto !important;
  padding: 9px !important;
  width: 60% !important;
  height: 35px !important;
  border-radius: 3px;
  z-index: 0.1 !important;
  background-color: #fff;
  -webkit-box-shadow: 0 -1px 7px 0 hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 -1px 7px 0 hsla(0, 0%, 100%, 0.5);
}
</style>
